import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../../../../shared/projects/values/environment.token';
import { Environment } from '../../../../types/environment';
import { Class } from '../../../../types/class';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';

@Injectable({
  providedIn: 'root',
})
export class ClassService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment
  ) {}

  createClassNew(
    newClass: Class
  ): Observable<globalThis.Realm.Services.MongoDB.InsertOneResult<any>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Class>(this._environmentToken.classScheduleCollection)
        .insertOne(newClass)
    );
  }

  getInstitutionClassListCount(institutionID: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Class>(this._environmentToken.classScheduleCollection)
        .count({
          institution_id: institutionID,
        })
    );
  }

  getInstitutionClassList(institutionID: string): Observable<Array<Class>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Class>(this._environmentToken.classScheduleCollection)
        .aggregate([
          {
            $match: {
              institution_id: institutionID,
            },
          },
          {
            $sort: {
              _id: -1,
            },
          },
          {
            $lookup: {
              from: this._environmentToken.userProfileCollection,
              localField: 'instructor_id',
              foreignField: 'owner_user_id',
              as: 'instructor',
            },
          },
          {
            $unwind: {
              path: '$instructor',
            },
          },
          {
            $set: {
              instructor_name: {
                $concat: [
                  '$instructor.first_name',
                  ' ',
                  '$instructor.last_name',
                ],
              },
            },
          },
          {
            $unset: ['instructor'],
          },
        ])
    );
  }

  getClass(classID: object): Observable<Class | null> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Class>(this._environmentToken.classScheduleCollection)
        .findOne({
          _id: classID,
        })
    );
  }

  getInstitutionClassScheduleForDay(
    institutionID: string,
    date: Date
  ): Observable<Array<Class>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Class>(this._environmentToken.classScheduleCollection)
        .aggregate([
          {
            $match: {
              institution_id: institutionID,
            },
          },
          {
            $set: {
              day_of_week: {
                $dateFromString: '$day_of_week',
              },
            },
          },
          {
            $set: {
              day_of_week: {
                $dayOfWeek: '$day_of_week',
              },
            },
          },
          {
            $match: {
              day_of_week: date.getDay() + 1,
            },
          },
        ])
    );
  }

  getClassAssignedToInstructor(instructorID: string): Observable<Array<Class>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Class>(this._environmentToken.classScheduleCollection)
        .find({
          instructor_id: instructorID,
        })
    );
  }
}
