import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { STUDENT_LIST_STATE } from '../values/state-names';
import { Student } from '../../../../types/student';
import { Class } from '../../../../types/class';
import { InstitutionService } from '../services/institution.service';
import { AdminService } from '../services/admin.service';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { retry, switchMap, tap } from 'rxjs/operators';
import { BSON } from 'realm-web';
import { FetchStudentListAction } from '../actions/student';
import { StudentService } from '../services/student.service';

@State<Array<Student>>({
  name: STUDENT_LIST_STATE,
  defaults: [],
})
@Injectable({
  providedIn: 'root',
})
export class StudentListState implements NgxsAfterBootstrap {
  constructor(
    private _store: Store,
    private _institutionService: InstitutionService,
    private _adminService: AdminService,
    private _studentService: StudentService,
    private _realmClientService: RealmClientService
  ) {}

  ngxsAfterBootstrap(stateContext: StateContext<Array<Class>>) {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch(new FetchStudentListAction());
    }
  }

  @Action(FetchStudentListAction)
  fetchStudentList(stateContext: StateContext<Array<Student>>) {
    return this._adminService
      .getAdmin(this._realmClientService.realmClient.currentUser!.id)
      .pipe(
        switchMap((adminProfile) => {
          return this._institutionService.getInstitutionProfile(
            new BSON.ObjectID(adminProfile?.assigned_institution_id)
          );
        }),
        switchMap((institution) => {
          return this._studentService.getStudentInInstitutionList(
            institution!._id.toString()
          );
        }),
        retry(3),
        tap((studentList) => {
          stateContext.setState([...studentList]);
        })
      );
  }
}
