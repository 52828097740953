import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Class } from '../../../../types/class';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { switchMap, retry, tap } from 'rxjs/operators';
import { PURCHASE_LIST_STATE } from '../values/state-names';
import { Purchase } from '../../../../types/purchase';
import { MembershipService } from '../services/membership.service';
import { FetchPurchaseInInstitutionListAction } from '../actions/membership';
import { AdminService } from '../services/admin.service';

@State<Array<Purchase>>({
  name: PURCHASE_LIST_STATE,
  defaults: [],
})
@Injectable()
export class PurchaseListState implements NgxsAfterBootstrap {
  constructor(
    private _store: Store,
    private _membershipService: MembershipService,
    private _adminService: AdminService,
    private _realmClientService: RealmClientService
  ) {}

  ngxsAfterBootstrap(stateContext: StateContext<Array<Class>>) {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch(new FetchPurchaseInInstitutionListAction());
    }
  }

  @Action(FetchPurchaseInInstitutionListAction)
  fetchPurchaseInInstitutionList(stateContext: StateContext<Array<Purchase>>) {
    return this._adminService
      .getAdmin(this._realmClientService.realmClient.currentUser.id)
      .pipe(
        switchMap((adminProfile) => {
          return this._membershipService.getInstitutionPurchaseList(
            adminProfile?.assigned_institution_id
          );
        }),
        retry(3),
        tap((purchaseInInstitutionList) => {
          stateContext.setState([...purchaseInInstitutionList]);
        })
      );
  }
}
