export const LOGIN_PATH = 'login';
export const WELCOME_PATH = 'welcome';
export const HOME_PATH = 'home';
export const DASHBOARD_PATH = 'dashboard';
export const STUDENT_PATH = 'students';
export const STUDENT_ATTENDANCE_PATH = 'student-attendance';
export const MANUAL_ATTENDANCE_PATH = 'manual-attendance';
export const MILESTONES_PATH = 'milestones';
export const MILESTONE_ITEM_PATH = 'milestone-item';
export const MILESTONES_ID_PATH = 'milestonesID';
export const INSTRUCTOR_PATH = 'instructors';
export const CLASS_LIST_PATH = 'classes';
export const CLASS_ID_PATH = 'classID';
export const MEMBERSHIP_LIST_PATH = 'memberships';
export const MEMBERSHIP_ID_PATH = 'membershipID';
export const BUSINESS_INFORMATION_PATH = 'business-information';
export const ADMIN_INFORMATION_PATH = 'admin-information';
export const BILLING_PATH = 'billing';
export const CANCELLATION_FEES_PATH = 'cancellation-fees';
export const PAYOUT_PATH = 'payout';
export const PAYOUT_RE_AUTH_PATH = 'stripe/reauth';
