import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../../../../shared/projects/values/environment.token';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { Environment } from '../../../../types/environment';
import { Admin } from '../../../../types/admin';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment
  ) {}

  getAdmin(adminUserID: string): Observable<Admin | null> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Admin>(this._environmentToken.userProfileCollection)
        .findOne({
          owner_user_id: adminUserID,
        })
    );
  }

  getAdminByAdminIdList(
    adminUserIdList: Array<string>
  ): Observable<Array<Admin>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Admin>(this._environmentToken.userProfileCollection)
        .find({
          owner_user_id: { $in: [...adminUserIdList] },
        })
    );
  }
}
