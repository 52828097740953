import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { INSTITUTION_PROFILE_STATE } from '../values/state-names';
import { Institution } from '../../../../types/institution';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { FetchInstitutionProfileAction } from '../actions/institution';
import { retry, switchMap, tap } from 'rxjs/operators';
import { BSON } from 'realm-web';
import { AdminService } from '../services/admin.service';
import { InstitutionService } from '../services/institution.service';

@State<Institution | null>({
  name: INSTITUTION_PROFILE_STATE,
  defaults: null,
})
@Injectable({
  providedIn: 'root',
})
export class InstitutionProfileState implements NgxsAfterBootstrap {
  constructor(
    private _store: Store,
    private _adminService: AdminService,
    private _institutionService: InstitutionService,
    private _realmClientService: RealmClientService
  ) {}

  ngxsAfterBootstrap() {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch(new FetchInstitutionProfileAction());
    }
  }

  @Action(FetchInstitutionProfileAction)
  fetchInstitutionProfile(stateContext: StateContext<Institution>) {
    return this._adminService
      .getAdmin(this._realmClientService.realmClient.currentUser!.id)
      .pipe(
        switchMap((adminProfile) => {
          return this._institutionService.getInstitutionProfile(
            new BSON.ObjectID(adminProfile?.assigned_institution_id)
          );
        }),
        retry(3),
        tap((institutionProfile) => {
          stateContext.patchState({
            ...institutionProfile,
          });
        })
      );
  }
}
