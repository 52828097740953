import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import {
  BILLING_PATH,
  HOME_PATH,
  LOGIN_PATH,
  WELCOME_PATH,
} from './values/values';
import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { IsNotLoggedInGuard } from './guards/is-not-logged-in.guard';
import {
  MEMBERSHIP_FAILURE_PATH,
  MEMBERSHIP_SUCCESS_PATH,
  SETTINGS_PATH,
} from '../../../values/paths';

const routes: Routes = [
  {
    path: '',
    redirectTo: HOME_PATH,
    pathMatch: 'full',
  },
  {
    path: HOME_PATH,
    loadChildren: () =>
      import('./home/home.module').then((module) => module.HomeModule),
    canActivate: [IsLoggedInGuard],
  },
  {
    path: `${HOME_PATH}/${SETTINGS_PATH}/${BILLING_PATH}/${MEMBERSHIP_SUCCESS_PATH}`,
    loadComponent: () =>
      import('./billing-success/billing-success.component').then(
        (module) => module.BillingSuccessComponent
      ),
    canActivate: [IsLoggedInGuard],
  },
  {
    path: `${HOME_PATH}/${SETTINGS_PATH}/${BILLING_PATH}/${MEMBERSHIP_FAILURE_PATH}`,
    loadComponent: () =>
      import('./billing-failure/billing-failure.component').then(
        (module) => module.BillingFailureComponent
      ),
    canActivate: [IsLoggedInGuard],
  },
  {
    path: LOGIN_PATH,
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [IsNotLoggedInGuard],
  },
  {
    path: WELCOME_PATH,
    loadChildren: () =>
      import('./welcome/welcome.module').then((m) => m.WelcomeModule),
    canActivate: [IsLoggedInGuard],
  },
];

export const ROUTING_CONFIG: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};
@NgModule({
  imports: [RouterModule.forRoot(routes, ROUTING_CONFIG)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
