import { inject, Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { InstitutionService } from '../services/institution.service';
import { INSTITUTION_SUBSCRIPTION_LIST_STATE } from '../values/state-names';
import { InstitutionSubscription } from '../../../../types/institution-subscription';
import { FetchInstitutionSubscriptionListAction } from '../actions/institution';
import { Observable } from 'rxjs';
import { Institution } from '../../../../types/institution';
import { InstitutionProfileState } from './institution-profile.state.';
import { first, switchMap, tap } from 'rxjs/operators';
import { AdminService } from '../services/admin.service';
import { BSON } from 'realm-web';

@State<Array<InstitutionSubscription>>({
  name: INSTITUTION_SUBSCRIPTION_LIST_STATE,
  defaults: [],
})
@Injectable()
export class InstitutionSubscriptionListState implements NgxsAfterBootstrap {
  institutionProfile$: Observable<Institution> = inject(Store).select(
    InstitutionProfileState
  );

  constructor(
    private _store: Store,
    private _institutionService: InstitutionService,
    private _adminService: AdminService,
    private _realmClientService: RealmClientService
  ) {
  }

  ngxsAfterBootstrap() {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch(new FetchInstitutionSubscriptionListAction());
    }
  }

  @Action(FetchInstitutionSubscriptionListAction)
  fetchInstitutionSubscriptionListAction(
    stateContext: StateContext<Array<InstitutionSubscription>>
  ) {
    return this._adminService
      .getAdmin(this._realmClientService.realmClient.currentUser!.id)
      .pipe(
        switchMap((adminProfile) => {
          return this._institutionService.getInstitutionProfile(
            new BSON.ObjectID(adminProfile?.assigned_institution_id)
          );
        }),
        switchMap((institution) => {
          return this._institutionService.getInstitutionSubscriptionList(
            institution._id.toString()
          );
        }),
        tap((subscriptionList) => {
          stateContext.setState([...subscriptionList]);
        }),
      );
  }
}
