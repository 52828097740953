import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  Select,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { MEMBERSHIP_LIST_STATE } from '../values/state-names';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { Membership } from '../../../../types/membership';
import { MembershipService } from '../services/membership.service';
import { InstitutionProfileState } from './institution-profile.state.';
import { Observable } from 'rxjs';
import { Institution } from '../../../../types/institution';
import { FetchMembershipInInstitutionListAction } from '../actions/membership';
import { AdminService } from '../services/admin.service';

@State<Array<Membership>>({
  name: MEMBERSHIP_LIST_STATE,
  defaults: [],
})
@Injectable({
  providedIn: 'root',
})
export class MembershipListState implements NgxsAfterBootstrap {
  @Select(InstitutionProfileState) institutionProfile$: Observable<Institution>;

  constructor(
    private _store: Store,
    private _adminService: AdminService,
    private _membershipService: MembershipService,
    private _realmClientService: RealmClientService
  ) {}

  ngxsAfterBootstrap() {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch(new FetchMembershipInInstitutionListAction());
    }
  }

  @Action(FetchMembershipInInstitutionListAction)
  fetchMembershipListNew(stateContext: StateContext<Array<Membership>>) {
    return this.institutionProfile$.pipe(
      switchMap((institution) => {
        return this._membershipService
          .getInstitutionMembershipList(institution?._id.toString())
          .pipe(
            switchMap((membershipList) => {
              return this._membershipService
                .getInstitutionPurchaseList(institution?._id.toString())
                .pipe(
                  switchMap((purchaseList) => {
                    const PURCHASE_CREATOR_ID_LIST = membershipList?.map(
                      (membership) => membership?.created_by_id
                    );
                    return this._adminService
                      .getAdminByAdminIdList(PURCHASE_CREATOR_ID_LIST)
                      .pipe(
                        map((adminProfileList) => {
                          return membershipList.map((membership) => {
                            const ADMIN = adminProfileList?.find(
                              (admin) =>
                                admin?.owner_user_id ===
                                membership.created_by_id
                            );
                            return {
                              ...membership,
                              active_subscription_count: purchaseList?.filter(
                                (purchase) =>
                                  purchase?.membership_id ===
                                    membership._id.toString() &&
                                  ['incomplete_expired', 'canceled'].includes(
                                    purchase.stripe_subscription_status
                                  ) === false
                              )?.length,
                              instructor_name: `${ADMIN?.first_name || ''} ${
                                ADMIN?.last_name || ''
                              }`,
                            };
                          });
                        })
                      );
                  })
                );
            })
          );
      }),
      tap((membershipList) => {
        stateContext.setState([...membershipList]);
      })
    );
  }
}
