import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { ClassService } from '../services/class.service';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { switchMap, retry, tap } from 'rxjs/operators';
import { AdminService } from '../services/admin.service';
import { PAYMENT_LIST_STATE } from '../values/state-names';
import { PaymentsInstitutionListAction } from '../actions/membership';
import { Payment } from '../../../../types/payment';
import { MembershipService } from '../services/membership.service';

@State<Array<Payment>>({
  name: PAYMENT_LIST_STATE,
  defaults: [],
})
@Injectable()
export class PaymentListState implements NgxsAfterBootstrap {
  constructor(
    private _store: Store,
    private _membershipService: MembershipService,
    private _adminService: AdminService,
    private _classService: ClassService,
    private _realmClientService: RealmClientService
  ) {}

  ngxsAfterBootstrap() {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch(new PaymentsInstitutionListAction());
    }
  }

  @Action(PaymentsInstitutionListAction)
  fetchClassList(stateContext: StateContext<Array<Payment>>) {
    return this._adminService
      .getAdmin(this._realmClientService.realmClient.currentUser!.id)
      .pipe(
        switchMap((adminProfile) => {
          return this._membershipService.getInstitutionPaymentList(
            adminProfile.assigned_institution_id
          );
        }),
        retry(3),
        tap((classList) => {
          stateContext.setState([...classList]);
        })
      );
  }
}
