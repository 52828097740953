import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENVIRONMENT_TOKEN } from '../../../../shared/projects/values/environment.token';
import { Environment } from '../../../../types/environment';
import { Session } from '../../../../types/session';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment
  ) {}

  getSessionByInstitutionCurrentMonthCount(
    institutionID: string
  ): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .aggregate([
          {
            $match: {
              institution_id: institutionID,
            },
          },
          {
            $set: {
              date: {
                $dateFromString: {
                  dateString: '$date',
                },
              },
            },
          },
          {
            $match: {
              date: {
                $gte: new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  0
                ),
              },
            },
          },
        ])
    ).pipe(
      map((aggregate) => {
        return aggregate.length;
      })
    );
  }

  getSessionsByInstitutionCount(institutionID: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .count({
          institution_id: institutionID,
        })
    );
  }

  getSessionsByInstitutionList(
    institutionID: string
  ): Observable<Array<Session>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .find({
          institution_id: institutionID,
        })
    );
  }

  getSessionByInstitutionTodayCount(institutionID: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .aggregate([
          {
            $match: {
              institution_id: institutionID,
            },
          },
          {
            $set: {
              date: {
                $dateFromString: {
                  dateString: '$date',
                },
              },
            },
          },
          {
            $match: {
              date: {
                $gte: new Date(new Date().setHours(0, 0, 0)),
                $lt: new Date(new Date().setHours(23, 59, 59)),
              },
            },
          },
        ])
    ).pipe(
      map((aggregate) => {
        return aggregate.length;
      })
    );
  }

  getSessionsByInstructorList(
    instructorID: string
  ): Observable<Array<Session>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .find(
          {
            instructor_id: instructorID,
          },
          {
            sort: {
              _id: -1,
            },
          }
        )
    );
  }

  getStudentSessionList(studentID: string): Observable<Array<Session>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .aggregate([
          {
            $match: {
              student_id: studentID,
            },
          },
          {
            $addFields: {
              institution_object_id: {
                $toObjectId: '$institution_id',
              },
            },
          },
          {
            $addFields: {
              class_object_id: {
                $toObjectId: '$class_id',
              },
            },
          },
          {
            $lookup: {
              from: this._environmentToken.institutionProfileCollection,
              localField: 'institution_object_id',
              foreignField: '_id',
              as: 'institution',
            },
          },
          {
            $lookup: {
              from: this._environmentToken.classScheduleCollection,
              localField: 'class_object_id',
              foreignField: '_id',
              as: 'class',
            },
          },
          {
            $unwind: {
              path: '$institution',
            },
          },
          {
            $set: {
              institution_name: '$institution.name',
            },
          },
          {
            $unset: ['institution', 'institution_object_id'],
          },
          {
            $sort: {
              date: -1,
            },
          },
          {
            $unwind: {
              path: '$class',
            },
          },
          {
            $set: {
              class_title: '$class.title',
              class_start_time: '$class.start_time',
              class_end_time: '$class.end_time',
            },
          },
          {
            $unset: ['class', 'class_object_id'],
          },
        ])
    );
  }

  getSession(classID: object): Observable<Session | null> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .findOne({
          _id: classID,
        })
    );
  }

  createSession(
    newSession: Session
  ): Observable<globalThis.Realm.Services.MongoDB.InsertOneResult<any>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .insertOne(newSession)
    );
  }

  getStudentSessionInInstitutionListCount(
    studentID: string,
    institutionID: string
  ): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .count({
          student_id: studentID,
          institution_id: institutionID,
        })
    );
  }

  getLastSession(
    studentID: string,
    institutionID: string
  ): Observable<Session | null> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .findOne(
          {
            student_id: studentID,
            institution_id: institutionID,
          },
          {
            sort: {
              _id: -1,
            },
          }
        )
    );
  }

  getStudentSessionListCount(studentID: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .count({
          student_id: studentID,
        })
    );
  }

  getStudentSessionListCurrentMonthCount(
    studentID: string
  ): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .aggregate([
          {
            $match: {
              student_id: studentID,
            },
          },
          {
            $set: {
              date: {
                $dateFromString: {
                  dateString: '$date',
                },
              },
            },
          },
          {
            $match: {
              date: {
                $gte: new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  0
                ),
              },
            },
          },
        ])
    ).pipe(
      map((aggregate) => {
        return aggregate.length;
      })
    );
  }

  createSessionList(
    sessionList: Session[]
  ): Observable<globalThis.Realm.Services.MongoDB.InsertManyResult<any>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Session>(this._environmentToken.classCollection)
        .insertMany([...sessionList])
    );
  }
}
