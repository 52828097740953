import { inject, Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { InstitutionService } from '../services/institution.service';
import { INSTITUTION_PAYMENT_LIST_STATE } from '../values/state-names';
import { FetchInstitutionPaymentListAction } from '../actions/institution';
import { Observable } from 'rxjs';
import { Institution } from '../../../../types/institution';
import { InstitutionProfileState } from './institution-profile.state.';
import { first, switchMap, tap } from 'rxjs/operators';
import { InstitutionPayment } from '../../../../types/payment';

@State<Array<InstitutionPayment>>({
  name: INSTITUTION_PAYMENT_LIST_STATE,
  defaults: [],
})
@Injectable()
export class InstitutionPaymentListState implements NgxsAfterBootstrap {
  institutionProfile$: Observable<Institution> = inject(Store).select(
    InstitutionProfileState
  );

  constructor(
    private _store: Store,
    private _institutionService: InstitutionService,
    private _realmClientService: RealmClientService
  ) {}

  ngxsAfterBootstrap() {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this.institutionProfile$.pipe(first()).subscribe((institutionProfile) => {
        this._store.dispatch(new FetchInstitutionPaymentListAction());
      });
    }
  }

  @Action(FetchInstitutionPaymentListAction)
  fetchInstitutionPaymentListAction(
    stateContext: StateContext<Array<InstitutionPayment>>
  ) {
    this.institutionProfile$.pipe(
      switchMap((institution) => {
        return this._institutionService.getInstitutionPaymentList(
          institution._id.toString()
        );
      }),
      tap((institutionPaymentList) => {
        stateContext.setState([...institutionPaymentList]);
      }),
      first()
    );
  }
}
