// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appID: 'beltstripe-apps-rvgmu',
  beltstripeDB: 'beltstripe',
  classCollection: 'classes_dev',
  classScheduleCollection: 'class_schedules_dev',
  createMembershipFunction: 'CREATE_STRIPE_PRODUCT',
  getFailedPaymentInfoFunction: 'GET_STRIPE_FAILED_PAYMENT_INFO_DEV',
  getStripeAccountFunction: 'GET_STRIPE_ACCOUNT_DETAILS_DEV',
  getStripeAccountLinkURL_Function: 'GENERATE_STRIPE_ACCOUNT_LINK_URL',
  getStripeAccountLoginLink: 'GET_STRIPE_ACCOUNT_LOGIN_LINK_DEV',
  getStripeInstitutionMembershipPortalFunction: 'GET_STRIPE_INSTITUTION_MEMBERSHIP_PORTAL_DEV',
  institutionProfileCollection: 'institutions_dev',
  inviteNewInstructorFunction: 'ENROL_NEW_INSTRUCTOR_DEV',
  inviteNewStudentFunction: 'INVITE_STUDENT_TO_INSTITUTION_DEV',
  membershipCollection: 'memberships_dev',
  milestoneCollection: 'milestones_dev',
  milestoneItemCollection: 'milestone_items_dev',
  paymentCollection: 'payments_dev',
  purchaseCollection: 'purchases_dev',
  updateMembershipFunction: 'UPDATE_STRIPE_PRODUCT_TEST',
  userProfileCollection: 'user_profiles_dev',
  getUpgradeMembershipSessionCheckoutFunction:
    'GET_INSTITUTION_PRODUCT_SESSION_CHECKOUT_URL_DEV',
  institutionPaymentsCollection: 'institution_payments_dev',
  institutionSubscriptionsCollection: 'institution_subscriptions_dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
