import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Class } from '../../../../types/class';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { switchMap, retry, tap } from 'rxjs/operators';
import { MILESTONE_ITEM_LIST_STATE } from '../values/state-names';
import { InstitutionProfileState } from './institution-profile.state.';
import { Institution } from '../../../../types/institution';
import { MilestoneItem } from '../../../../types/milestone';
import { MilestoneService } from '../services/milestone.service';
import { FetchMilestoneItemListAction } from '../actions/milestone';

@State<Array<MilestoneItem>>({
  name: MILESTONE_ITEM_LIST_STATE,
  defaults: [],
})
@Injectable()
export class MilestoneItemListState implements NgxsAfterBootstrap {
  constructor(
    private _store: Store,
    private _milestoneService: MilestoneService,
    private _realmClientService: RealmClientService
  ) {}

  ngxsAfterBootstrap(stateContext: StateContext<Array<Class>>) {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch(new FetchMilestoneItemListAction());
    }
  }

  @Action(FetchMilestoneItemListAction)
  fetchMilestoneList(stateContext: StateContext<Array<MilestoneItem>>) {
    return this._store.select<Institution>(InstitutionProfileState).pipe(
      switchMap((institutionProfile) => {
        return this._milestoneService.getMilestoneItemInInstitutionList(
          institutionProfile?._id.toString()
        );
      }),
      retry(3),
      tap((milestoneItemList) => {
        stateContext.setState([...milestoneItemList]);
      })
    );
  }
}
