export const LOGIN_PATH = "login";
export const CLASS_LIST_PATH = "class-list";
export const SETTINGS_PATH = "settings";
export const EMERGENCY_CONTACT_PATH = "emergency-contact";
export const EMERGENCY_CONTACT_LIST_PATH = "emergency-contact-list";
export const ADD_EMERGENCY_CONTACT_PATH = "add-emergency-contact";
export const EDIT_EMERGENCY_CONTACT_PATH = "edit-emergency-contact";
export const ATTENDANCE_PATH = "attendance";
export const STUDENT_ATTENDANCE_LIST_PATH = "student-attendance-list";
export const PAYMENTS_PATH = "payments";
export const STUDENTS_LIST_PATH = "students";
export const SIGN_UP_PATH = "sign-up";
export const COMPLETE_ACCOUNT = "complete-account";
export const HOME_PATH = "home";
export const INSTITUTION_LIST_PATH = "institutions";
export const RESET_PASSWORD_PATH = "reset-password";
export const MEMBERSHIP_LIST_PATH = "memberships";
export const MEMBERSHIP_SUCCESS_PATH = "success";
export const MEMBERSHIP_FAILURE_PATH = "cancel";
export const CLASS_SCHEDULE_DETAIL_PATH = "class-schedule-detail";
export const CLASS_ID_PARAM_PATH = "classID";
export const PROFILE_PATH = "profile";
export const EDIT_PROFILE_PATH = "edit-profile";
export const USER_ID_ROUTE_PARAM = "userID";
export const USER_PATH = "user";
export const SELECT_PATH = "select";
export const EMERGENCY_CONTACT_ID_ROUTE_PARAM = "emergencyContactID";
