import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { INSTRUCTOR_LIST_STATE } from '../values/state-names';
import { Instructor } from '../../../../types/instructor';
import { InstitutionService } from '../services/institution.service';
import { AdminService } from '../services/admin.service';
import { InstructorService } from '../services/instructor.service';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { retry, switchMap, tap } from 'rxjs/operators';
import { BSON } from 'realm-web';
import {
  AddInstructorAction,
  FetchInstructorListAction,
} from '../actions/instructor';

@State<Array<Instructor>>({
  name: INSTRUCTOR_LIST_STATE,
  defaults: [],
})
@Injectable({
  providedIn: 'root',
})
export class InstructorListState implements NgxsAfterBootstrap {
  constructor(
    private _store: Store,
    private _institutionService: InstitutionService,
    private _adminService: AdminService,
    private _instructorService: InstructorService,
    private _realmClientService: RealmClientService
  ) {}

  ngxsAfterBootstrap() {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch(new FetchInstructorListAction());
    }
  }

  @Action(AddInstructorAction)
  addInstructor(
    stateContext: StateContext<Array<Instructor>>,
    addInstructorAction: AddInstructorAction
  ) {
    const STATE = stateContext.getState();
    stateContext.setState([addInstructorAction.newInstructor, ...STATE]);
  }

  @Action(FetchInstructorListAction)
  fetchInstructorList(stateContext: StateContext<Array<Instructor>>) {
    return this._adminService
      .getAdmin(this._realmClientService.realmClient.currentUser!.id)
      .pipe(
        switchMap((adminProfile) => {
          return this._institutionService.getInstitutionProfile(
            new BSON.ObjectID(adminProfile?.assigned_institution_id)
          );
        }),
        switchMap((institution) => {
          return this._instructorService.getInstructorInInstitutionList(
            institution!._id.toString()
          );
        }),
        retry(3),
        tap((instructorList) => {
          stateContext.setState([...instructorList]);
        })
      );
  }
}
