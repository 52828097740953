import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Class } from '../../../../types/class';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { switchMap, retry, tap } from 'rxjs/operators';
import { MILESTONE_LIST_STATE } from '../values/state-names';
import { InstitutionProfileState } from './institution-profile.state.';
import { Institution } from '../../../../types/institution';
import { Milestone } from '../../../../types/milestone';
import { MilestoneService } from '../services/milestone.service';
import {
  AddMilestoneAction,
  ArchiveMilestoneAction,
  FetchMilestoneListAction,
} from '../actions/milestone';

@State<Array<Milestone>>({
  name: MILESTONE_LIST_STATE,
  defaults: [],
})
@Injectable()
export class MilestoneListState implements NgxsAfterBootstrap {
  constructor(
    private _store: Store,
    private _milestoneService: MilestoneService,
    private _realmClientService: RealmClientService
  ) {}

  ngxsAfterBootstrap(stateContext: StateContext<Array<Class>>) {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch(new FetchMilestoneListAction());
    }
  }

  @Action(AddMilestoneAction)
  addMilestone(
    stateContext: StateContext<Array<Milestone>>,
    addMilestoneAction: AddMilestoneAction
  ) {
    const STATE = stateContext.getState();

    stateContext.patchState([addMilestoneAction.milestone, ...STATE]);
  }

  @Action(ArchiveMilestoneAction)
  archiveMilestone(
    stateContext: StateContext<Array<Milestone>>,
    archiveMilestoneAction: ArchiveMilestoneAction
  ) {
    const STATE = stateContext.getState();
    const MILESTONE_TO_ARCHIVE_INDEX = STATE.findIndex(
      (milestone) =>
        milestone?._id.toString() ===
        archiveMilestoneAction.archivedMilestone._id.toString()
    );
    const MILESTONE_LIST = [...STATE];
    if (MILESTONE_TO_ARCHIVE_INDEX !== -1) {
      MILESTONE_LIST[MILESTONE_TO_ARCHIVE_INDEX] = {
        ...archiveMilestoneAction.archivedMilestone,
      };
    }

    stateContext.patchState([...MILESTONE_LIST]);
  }

  @Action(FetchMilestoneListAction)
  fetchMilestoneList(stateContext: StateContext<Array<Milestone>>) {
    return this._store.select<Institution>(InstitutionProfileState).pipe(
      switchMap((institutionProfile) => {
        return this._milestoneService.getMilestoneInInstitutionList(
          institutionProfile?._id.toString()
        );
      }),
      retry(3),
      tap((milestoneList) => {
        stateContext.setState([...milestoneList]);
      })
    );
  }
}
