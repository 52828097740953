import { Class } from '../../../../types/class';

export class AddClassAction {
  static readonly type = '[Admin Class Page] Add Class';
  constructor(public newClass: Class) {}
}


export class FetchClassListAction {
  static readonly type = '[Admin Class Page] Fetch Class List';
  constructor() {}
}
