import { Inject, Injectable } from '@angular/core';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { ENVIRONMENT_TOKEN } from '../../../../shared/projects/values/environment.token';
import { Environment } from '../../../../types/environment';
import { from, Observable } from 'rxjs';
import { Milestone, MilestoneItem } from '../../../../types/milestone';

@Injectable({
  providedIn: 'root',
})
export class MilestoneService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment
  ) {}

  getMilestoneInInstitutionList(
    institutionID: string
  ): Observable<Array<Milestone>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Milestone>(this._environmentToken.milestoneCollection)
        .find({
          institution_id: institutionID,
        })
    );
  }

  getMilestoneItemInInstitutionList(
    institutionID: string
  ): Observable<Array<MilestoneItem>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<MilestoneItem>(
          this._environmentToken.milestoneItemCollection
        )
        .find({
          institution_id: institutionID,
        })
    );
  }

  createMilestone(
    milestone: Milestone
  ): Observable<globalThis.Realm.Services.MongoDB.InsertOneResult<any>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Milestone>(this._environmentToken.milestoneCollection)
        .insertOne({
          ...milestone,
        })
    );
  }

  getMilestone(milestoneID: object): Observable<Milestone> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Milestone>(this._environmentToken.milestoneCollection)
        .findOne({
          _id: milestoneID,
        })
    );
  }

  archiveMilestone(
    milestoneID: object,
    milestoneToUpdate: Milestone
  ): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Milestone>(this._environmentToken.milestoneCollection)
        .updateOne(
          {
            _id: milestoneID,
          },
          {
            $set: {
              ...milestoneToUpdate,
            },
          }
        )
    );
  }

  completeMilestone(
    milestoneID: object,
    completedByUserID: string,
    completionComment: string
  ) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Milestone>(this._environmentToken.milestoneItemCollection)
        .updateOne(
          {
            _id: milestoneID,
          },
          {
            $set: {
              completed_by_user_id: completedByUserID,
              completion_comment: completionComment,
              completion_date: new Date().toDateString(),
            },
          }
        )
    );
  }
}
