import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Class } from '../../../../types/class';
import { ClassService } from '../services/class.service';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { switchMap, retry, tap } from 'rxjs/operators';
import { AdminService } from '../services/admin.service';
import { InstitutionService } from '../services/institution.service';
import { AddClassAction } from '../actions/class';
import { FetchClassListAction } from '../actions/class';
import { CLASS_LIST_STATE } from '../values/state-names';
import { InstitutionProfileState } from './institution-profile.state.';
import { Institution } from '../../../../types/institution';

@State<Array<Class>>({
  name: CLASS_LIST_STATE,
  defaults: [],
})
@Injectable()
export class ClassListState implements NgxsAfterBootstrap {
  constructor(
    private _store: Store,
    private _institutionService: InstitutionService,
    private _adminService: AdminService,
    private _classService: ClassService,
    private _realmClientService: RealmClientService
  ) {}

  ngxsAfterBootstrap(stateContext: StateContext<Array<Class>>) {
    if (
      this._realmClientService?.realmClient?.currentUser?.isLoggedIn === true
    ) {
      this._store.dispatch(new FetchClassListAction());
    }
  }

  @Action(AddClassAction)
  addClass(
    stateContext: StateContext<Array<Class>>,
    addClassAction: AddClassAction
  ) {
    const STATE = stateContext.getState();

    stateContext.setState([...STATE, addClassAction.newClass]);
  }

  @Action(FetchClassListAction)
  fetchClassList(stateContext: StateContext<Array<Class>>) {
    return this._store.select<Institution>(InstitutionProfileState).pipe(
      switchMap((institutionProfile) => {
        return this._classService.getInstitutionClassList(
          institutionProfile?._id.toString()
        );
      }),
      retry(3),
      tap((classList) => {
        stateContext.setState([...classList]);
      })
    );
  }
}
