import { Injectable } from '@angular/core';
import * as Realm from 'realm-web';

@Injectable({
  providedIn: 'root'
})
export class RealmClientService {
  get mongoRemoteClient(): globalThis.Realm.Services.MongoDB {
    if(!this._mongoRemoteClient){
     this.initialiseMongoRemoteClient();
    }
    return this._mongoRemoteClient;
  }

  set mongoRemoteClient(value: globalThis.Realm.Services.MongoDB) {
    this._mongoRemoteClient = value;
  }

  realmClient!: Realm.App;
  private _mongoRemoteClient!: globalThis.Realm.Services.MongoDB;

  constructor() { }

  initialiseRealmClient(appID: string): Realm.App{
    return this.realmClient = new Realm.App({
      id: appID
    });
  }

  initialiseMongoRemoteClient(){
    // @ts-ignore
    this._mongoRemoteClient = this.realmClient.currentUser?.mongoClient("mongodb-atlas");
  }

}
