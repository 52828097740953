export const CLASS_LIST_STATE = 'classList';
export const STUDENT_LIST_STATE = 'studentList';
export const SESSION_LIST_STATE = 'sessionList';
export const INSTRUCTOR_LIST_STATE = 'instructorList';
export const INSTITUTION_PROFILE_STATE = 'institutionProfile';
export const SIDE_MENU_STATE = 'sideMenu';
export const USER_PROFILE_STATE = 'userProfile';
export const INSTITUTION_LIST_STATE = 'academyList';
export const SELECTED_CLASS_STATE = 'selectedClass';
export const SELECTED_STUDENT_STATE = 'selectedStudent';
export const MEMBERSHIP_LIST_STATE = 'membershipList';
export const PURCHASE_LIST_STATE = 'purchaseList';
export const PAYMENT_LIST_STATE = 'paymentList';
export const MILESTONE_LIST_STATE = 'milestoneList';
export const MILESTONE_ITEM_LIST_STATE = 'milestoneItemList';
export const INSTITUTION_SUBSCRIPTION_LIST_STATE =
  'institutionSubscriptionList';
export const INSTITUTION_PAYMENT_LIST_STATE = 'institutionPaymentList';
