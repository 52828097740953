import { Milestone } from '../../../../types/milestone';

export class AddMilestoneAction {
  static readonly type = '[Milestone Page] Add Milestone';
  constructor(public milestone: Milestone) {}
}

export class ArchiveMilestoneAction {
  static readonly type = '[Milestone Page] Archive Milestone';
  constructor(public archivedMilestone: Milestone) {}
}

export class FetchMilestoneListAction {
  static readonly type = '[Milestone List Page] Fetch Milestone List';
  constructor() {}
}

export class FetchMilestoneItemListAction {
  static readonly type = '[Milestone Detail Page] Fetch Milestone Item List';
  constructor() {}
}
