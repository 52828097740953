import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { RealmClientService } from '../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { ENVIRONMENT_TOKEN } from '../../../shared/projects/values/environment.token';
import { ClassListState } from './states/class-list-state';
import { StudentListState } from './states/student-list.state';
import { SessionListState } from './states/session-list.state';
import { InstructorListState } from './states/instructor-list.state';
import { InstitutionProfileState } from './states/institution-profile.state.';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { MembershipListState } from './states/membership-list-state';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PurchaseListState } from './states/purchase-list-state';
import { PaymentListState } from './states/payment-list-state';
import { MilestoneListState } from './states/milestone-list.state';
import { MilestoneItemListState } from './states/milestone-item-list.state';
import { InstitutionSubscriptionListState } from './states/institution-subscription-list-state.service';
import { InstitutionPaymentListState } from './states/institution-payment-list-state.service';

export function INITIALISE_REAL_AND_LOGIN_WITH_API_KEY(
  realmClientService: RealmClientService
) {
  return () => {
    realmClientService.initialiseRealmClient(environment.appID);
    realmClientService.initialiseMongoRemoteClient();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxsModule.forRoot(
      [
        ClassListState,
        StudentListState,
        SessionListState,
        InstructorListState,
        InstitutionProfileState,
        MembershipListState,
        PurchaseListState,
        PaymentListState,
        MilestoneListState,
        MilestoneItemListState,
        InstitutionSubscriptionListState,
        InstitutionPaymentListState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsResetPluginModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NotificationModule,
    IndicatorsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: INITIALISE_REAL_AND_LOGIN_WITH_API_KEY,
      deps: [RealmClientService],
      multi: true,
    },
    {
      provide: ENVIRONMENT_TOKEN,
      useValue: environment,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
