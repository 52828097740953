import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RealmClientService } from "../../../../shared/projects/sdk/src/lib/services/realm-client.service";
import { LOGIN_PATH } from "../values/values";


@Injectable({
  providedIn: 'root'
})
export class IsLoggedInGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _realmClientService: RealmClientService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this._realmClientService.realmClient.currentUser?.isLoggedIn){
      return true;
    } else {
      void this._router.navigate([`/${LOGIN_PATH}`]);
      return false
    }
  }

}
