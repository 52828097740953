import { Session } from '../../../../types/session';

export class FetchSessionListAction {
  static readonly type = '[Admin Dashboard Page] Fetch Session List';
  constructor() {}
}

export class AddSessionListAction {
  static readonly type = '[Admin Attendance Page] Add Session List';
  constructor(public sessionList: Array<Session>) {}
}
