import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../../../../shared/projects/values/environment.token';
import { Environment } from '../../../../types/environment';
import {
  Institution,
  StripeAccount,
  StripeAccountLoginLink,
  StripeURL_AccountLink,
} from '../../../../types/institution';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { InstitutionSubscription } from '../../../../types/institution-subscription';
import { InstitutionPayment } from '../../../../types/payment';

@Injectable({
  providedIn: 'root',
})
export class InstitutionService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment
  ) {}

  getInstitutionStripeAccount(
    stripeAccountID: string
  ): Observable<StripeAccount> {
    return from(
      this._realmClientService.realmClient.currentUser!.callFunction<StripeAccount>(
        this._environmentToken.getStripeAccountFunction,
        stripeAccountID
      )
    );
  }

  getAllInstitutionList(): Observable<Array<Institution>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Institution>(
          this._environmentToken.institutionProfileCollection
        )
        .find({
          has_completed_initial_onboard: true,
        })
    );
  }

  getStripeAccountLoginLink(
    stripeAccountID: string
  ): Observable<StripeAccountLoginLink> {
    return from(
      this._realmClientService.realmClient.currentUser!.callFunction<StripeAccountLoginLink>(
        this._environmentToken.getStripeAccountLoginLink,
        stripeAccountID
      )
    );
  }

  getStripeAccountLinkURL(
    institutionId: string,
    isAccountUpdate: boolean = false
  ): Observable<StripeURL_AccountLink> {
    return from(
      this._realmClientService.realmClient.currentUser!.callFunction<StripeURL_AccountLink>(
        this._environmentToken.getStripeAccountLinkURL_Function,
        institutionId,
        isAccountUpdate
      )
    );
  }

  getInstitutionProfile(institutionID: object): Observable<Institution | null> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Institution>(
          this._environmentToken.institutionProfileCollection
        )
        .findOne({
          _id: institutionID,
        })
    );
  }

  createInstitutionProfile(
    institution: Institution
  ): Observable<globalThis.Realm.Services.MongoDB.InsertOneResult<any>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Institution>(
          this._environmentToken.institutionProfileCollection
        )
        .insertOne(institution)
    );
  }

  updateInstitutionProfile(
    institution: Institution
  ): Observable<globalThis.Realm.Services.MongoDB.UpdateResult<any>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Institution>(
          this._environmentToken.institutionProfileCollection
        )
        .updateOne(
          {
            admin_created_by_id: institution.admin_created_by_id,
          },
          {
            $set: {
              ...institution,
            },
          },
          {
            upsert: true,
          }
        )
    );
  }

  findInstitutionListByIdList(institutionObjectIdList: Array<object>) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Institution>(
          this._environmentToken.institutionProfileCollection
        )
        .find({
          _id: {
            $in: institutionObjectIdList,
          },
        })
    );
  }

  getInstitutionSubscriptionList(
    institutionID: string
  ): Observable<Array<InstitutionSubscription>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<InstitutionSubscription>(
          this._environmentToken.institutionSubscriptionsCollection
        )
        .find({
          institution_id: institutionID,
        })
    );
  }

  getInstitutionPaymentList(
    institutionID: string
  ): Observable<Array<InstitutionPayment>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<InstitutionPayment>(
          this._environmentToken.institutionPaymentsCollection
        )
        .find({
          institution_id: institutionID,
        })
    );
  }
}
