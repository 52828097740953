import { Instructor } from '../../../../types/instructor';

export class FetchInstructorListAction {
  static readonly type = '[Instructor Page] Fetch Instructor List';
  constructor() {}
}

export class AddInstructorAction {
  static readonly type = '[Instructor Page] Add Instructor';
  constructor(public newInstructor: Instructor) {}
}
