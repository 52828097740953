import { Inject, Injectable } from '@angular/core';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';
import { from, Observable } from 'rxjs';
import { Membership } from '../../../../types/membership';
import { Environment } from '../../../../types/environment';
import { ENVIRONMENT_TOKEN } from '../../../../shared/projects/values/environment.token';
import { Purchase } from '../../../../types/purchase';
import { Payment } from '../../../../types/payment';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment
  ) {}

  getFailedPaymentInfo(
    stripeSubscriptionID: string
  ): Observable<FailedPaymentInfo> {
    return from(
      this._realmClientService.realmClient.currentUser!.callFunction<FailedPaymentInfo>(
        this._environmentToken.getFailedPaymentInfoFunction,
        stripeSubscriptionID
      )
    );
  }

  getCurrentActivePurchaseForStudent(
    studentOwnerID: string
  ): Observable<Purchase | null> {
    const CURRENT_TIME_IN_SECONDS = Date.now() / 1000;
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Purchase>(this._environmentToken.purchaseCollection)
        .findOne({
          customer_owner_user_id: studentOwnerID,
          type: 'subscription',
          billing_start_date: { $lte: CURRENT_TIME_IN_SECONDS },
          billing_expiry_date: { $gte: CURRENT_TIME_IN_SECONDS },
          stripe_subscription_status: {$ne: 'canceled'}
        })
    );
  }

  getInstitutionPurchaseList(
    institutionID: string
  ): Observable<Array<Purchase>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Purchase>(this._environmentToken.purchaseCollection)
        .find({
          institution_id: institutionID,
          type: 'subscription',
        })
    );
  }

  getInstitutionPaymentList(institutionID: string): Observable<Array<Payment>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Payment>(this._environmentToken.paymentCollection)
        .find({
          institution_id: institutionID,
        })
    );
  }

  getInstitutionMembershipList(
    institutionID: string
  ): Observable<Array<Membership>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Membership>(this._environmentToken.membershipCollection)
        .aggregate([
          {
            $match: {
              institution_id: institutionID,
            },
          },
          {
            $addFields: {
              instructor_object_id: {
                $toObjectId: '$created_by_id',
              },
            },
          },
          {
            $lookup: {
              from: this._environmentToken.userProfileCollection,
              localField: 'created_by_id',
              foreignField: 'owner_user_id',
              as: 'instructor',
            },
          },
          {
            $unwind: {
              path: '$instructor',
            },
          },
          {
            $set: {
              instructor_name: {
                $concat: [
                  '$instructor.first_name',
                  ' ',
                  '$instructor.last_name',
                ],
              },
            },
          },
          {
            $unset: ['instructor', 'instructor_object_id'],
          },
          {
            $set: {
              membership_id: {
                $toString: '$_id',
              },
            },
          },
          {
            $lookup: {
              from: this._environmentToken.purchaseCollection,
              localField: 'membership_id',
              foreignField: 'membership_id',
              as: 'purchases_list',
            },
          },
          {
            $unwind: {
              path: '$purchases_list',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $match: {
              $or: [
                {
                  purchases_list: {
                    $exists: false,
                  },
                },
                {
                  $and: [
                    {
                      'purchases_list.type': 'subscription',
                    },
                    {
                      'purchases_list.stripe_subscription_status': {
                        $nin: ['incomplete_expired', 'canceled'],
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            $group: {
              _id: '$_id',
              active_subscription_count: {
                $push: '$purchases_list',
              },
              title: {
                $first: '$title',
              },
              description: {
                $first: '$description',
              },
              currency_code: {
                $first: '$currency_code',
              },
              created_date: {
                $first: '$created_date',
              },
              billing_cycle: {
                $first: '$billing_cycle',
              },
              created_by_id: {
                $first: '$created_by_id',
              },
              institution_id: {
                $first: '$institution_id',
              },
              price: {
                $first: '$price',
              },
              session_count: {
                $first: '$session_count',
              },
              stripe_product_id: {
                $first: '$stripe_product_id',
              },
              stripe_price_id: {
                $first: '$stripe_price_id',
              },
              membership_id: {
                $first: '$membership_id',
              },
              has_unlimited_sessions: {
                $first: '$has_unlimited_sessions',
              },
              instructor_name: {
                $first: '$instructor_name',
              },
            },
          },
          {
            $set: {
              active_subscription_count: {
                $size: '$active_subscription_count',
              },
            },
          },
        ])
    );
  }

  getMembershipByInstitutionList(
    institutionID: string
  ): Observable<Array<Membership>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Membership>(this._environmentToken.membershipCollection)
        .find({
          institution_id: institutionID,
        })
    );
  }

  createMembership(membership: Membership): Observable<any> {
    return from(
      this._realmClientService.realmClient.currentUser!.callFunction(
        this._environmentToken.createMembershipFunction,
        membership
      )
    );
  }

  updateMembership(membershipUpdate: Membership): Observable<any> {
    return from(
      this._realmClientService.realmClient.currentUser!.callFunction(
        this._environmentToken.updateMembershipFunction,
        membershipUpdate
      )
    );
  }
}

export interface FailedPaymentInfo {
  charge_failure_code: string;
  charge_failure_message: string;
  hosted_invoice_url: string;
  amount_due: number;
  currency: string;
  attempt_count: string;
}
