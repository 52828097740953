import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../../../../shared/projects/values/environment.token';
import { Instructor } from '../../../../types/instructor';
import { Environment } from '../../../../types/environment';
import { Student } from '../../../../types/student';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';

@Injectable({
  providedIn: 'root',
})
export class InstructorService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment
  ) {}

  getInstructorInInstitutionList(
    institutionID: string
  ): Observable<Array<Instructor>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Instructor>(this._environmentToken.userProfileCollection)
        .find({
          instructor_institution_id_list: {
            $in: [institutionID],
          },
        })
    );
  }

  getInstructorInInstitutionListCount(
    institutionID: string
  ): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Instructor>(this._environmentToken.userProfileCollection)
        .count({
          instructor_institution_id_list: {
            $in: [institutionID],
          },
        })
    );
  }

  enrolNewInstructor(instructor: object) {
    return from(
      this._realmClientService.realmClient.currentUser!.callFunction(
        this._environmentToken.inviteNewInstructorFunction,
        [instructor]
      )
    );
  }

  enrolExistingInstructor(instructor: object, institutionID: string) {
    return from(
      this._realmClientService.realmClient.currentUser!.callFunction(
        'ENROL_EXISTING_INSTRUCTOR',
        [instructor, institutionID]
      )
    );
  }

  getInstructorProfileCount(email: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Instructor>(this._environmentToken.userProfileCollection)
        .count({
          email: email,
        })
    );
  }

  getInstructorProfile(instructorID: string): Observable<Instructor | null> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Instructor>(this._environmentToken.userProfileCollection)
        .findOne({
          owner_user_id: instructorID,
          instructor_institution_id_list: { $exists: true },
        })
    );
  }

  getInstructorListInInstructorIdList(
    institutionID: Array<string>
  ): Observable<Array<Instructor>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Instructor>(this._environmentToken.userProfileCollection)
        .find({
          owner_user_id: {
            $in: [...institutionID],
          },
        })
    );
  }

  getCoachProfile(coachID: string): Observable<Instructor | null> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Instructor>(this._environmentToken.userProfileCollection)
        .findOne({
          owner_user_id: coachID,
        })
    );
  }

  updateInstructorProfile(instructorID: string, profileUpdate: object) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Instructor>(this._environmentToken.userProfileCollection)
        .updateOne(
          {
            owner_user_id: instructorID,
          },
          {
            $set: {
              ...profileUpdate,
            },
          }
        )
    );
  }
}
