export class FetchMembershipInInstitutionListAction {
  static readonly type = '[Membership Page] Fetch Membership List';
  constructor() {}
}

export class FetchPurchaseInInstitutionListAction {
  static readonly type = '[Membership Detail Page] Fetch Purchase List';
  constructor() {}
}

export class PaymentsInstitutionListAction {
  static readonly type = '[Membership Detail Page] Fetch Payment List';
  constructor() {}
}
