import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../../../../shared/projects/values/environment.token';
import { Environment } from '../../../../types/environment';
import { EmergencyContact, Student } from '../../../../types/student';
import { RealmClientService } from '../../../../shared/projects/sdk/src/lib/services/realm-client.service';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  constructor(
    private _realmClientService: RealmClientService,
    @Inject(ENVIRONMENT_TOKEN) private _environmentToken: Environment
  ) {}

  getStudentInInstitutionList(
    institutionID: string
  ): Observable<Array<Student>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Student>(this._environmentToken.userProfileCollection)
        .find({
          institution_id_list: {
            $in: [institutionID],
          },
        })
    );
  }

  getStudentInInstitutionListCount(institutionID: string): Observable<number> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Student>(this._environmentToken.userProfileCollection)
        .count({
          institution_id_list: {
            $in: [institutionID],
          },
        })
    );
  }

  getStudentProfileList(
    studentIdList: Array<string>
  ): Observable<Array<Student>> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Student>(this._environmentToken.userProfileCollection)
        .find({
          owner_user_id: {
            $in: [...studentIdList],
          },
        })
    );
  }

  getStudentProfile(studentID: string): Observable<Student | null> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Student>(this._environmentToken.userProfileCollection)
        .findOne({
          owner_user_id: studentID,
        })
    );
  }

  inviteStudent(email: string): Observable<any> {
    return from(
      this._realmClientService.realmClient.currentUser!.callFunction(
        this._environmentToken.inviteNewStudentFunction,
        [email.trim().toLowerCase()]
      )
    );
  }

  joinInstitution(ownerUserID: string, institutionID: string) {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Student>(this._environmentToken.userProfileCollection)
        .updateOne(
          {
            owner_user_id: ownerUserID,
          },
          {
            $set: {
              institution_id_list: [institutionID],
            },
          }
        )
    );
  }

  updateStudentProfile(studentProfile: Student): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Student>(this._environmentToken.userProfileCollection)
        .updateOne(
          {
            owner_user_id: studentProfile.owner_user_id,
          },
          {
            $set: {
              ...studentProfile,
            },
          },
          {
            upsert: true,
          }
        )
    );
  }

  updateEmergencyContact(
    contact: EmergencyContact,
    studentOwnerUserID: string
  ): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Student>(this._environmentToken.userProfileCollection)
        .updateOne(
          {
            'emergency_contact_list._id': contact._id,
            owner_user_id: studentOwnerUserID,
          },
          {
            $set: {
              'emergency_contact_list.$': contact,
            },
          }
        )
    );
  }

  addEmergencyContact(
    contact: EmergencyContact,
    studentOwnerUserID: string
  ): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Student>(this._environmentToken.userProfileCollection)
        .updateOne(
          {
            owner_user_id: studentOwnerUserID,
          },
          {
            $push: {
              emergency_contact_list: contact,
            },
          }
        )
    );
  }

  removeEmergencyContact(contactID: object): Observable<any> {
    return from(
      this._realmClientService.mongoRemoteClient
        .db(this._environmentToken.beltstripeDB)
        .collection<Student>(this._environmentToken.userProfileCollection)
        .updateOne(
          {
            'emergency_contact_list._id': contactID,
          },
          {
            $pull: {
              'emergency_contact_list.': { _id: contactID },
            },
          }
        )
    );
  }
}
