export class FetchInstitutionProfileAction {
  static readonly type = 'Fetch Institution Profile';
  constructor() {}
}

export class FetchInstitutionSubscriptionListAction {
  static readonly type = 'Fetch Institution Subscription List';
  constructor() {}
}

export class FetchInstitutionPaymentListAction {
  static readonly type = 'Fetch Institution Payment List';
  constructor() {}
}
